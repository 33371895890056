import * as React from "react";
import { useEffect, useState } from "react";
import HcpLayout from "../../components/hcp/layout";
import { Seo } from "../../components/seo";
import { Col, Row } from "react-grid-system";
import { Link } from "gatsby";
import useDeviceSize from "../../hooks/use-device-size";

import "./wakix-for-you-support-program.scss";
import { TfiAngleRight } from "react-icons/tfi";
import icon1 from "../../images/icons/icons-rx.svg";
import icon2 from "../../images/icons/icons-shipment.svg";
import icon3 from "../../images/icons/icons-support.svg";
import icon4 from "../../images/icons/icons-resources.svg";
import copayIcon from "../../images/icons/icons-copay.svg";
import CustomVideo from "../../components/cards/customVideo";
import videoThumbnail from "../../images/icons/thumb-psp-video.webp";
import W4YThumbnail from "../../images/icons/thumb-specialist.webp";
import W4YLogo from "../../images/logos/wakix-for-you-logo.svg";
import SocialIconBlock from "../../components/socialIconBlock";
import resourceImg from "../../images/wakix-for-you/cta-resources.webp";
import resourceMobileImg from "../../images/wakix-for-you/cta-resources-mobile.webp";
import expectationsImg from "../../images/personal-stories/cta-expectations.webp";
import expectationsMobileImg from "../../images/personal-stories/cta-expectations-mobile.webp";

import bannerImg from "../../images/wakix-for-you/hero-wakix-for-you.webp";
import bannerImgMobile from "../../images/wakix-for-you/hero-wakix-for-you-mobile.webp";
import heroName from "../../images/wakix-for-you/hero-wakix-for-you-name.webp";
import InnerBanner from "../../components/InnerBanner";

const WakixForYouSupportProgram = () => {
    const deviceSizes = useDeviceSize();

    return (
        <HcpLayout pageClass="hcp-main support-program">
            <InnerBanner
                topHeading="<span>WAKIX for You</span> Support Program"
                bannerClass="support"
                bannerHeroImg={bannerImg}
                bannerHeroImgClass="banner-desktop"
                bannerHeroImgMobileClass="banner-mobile"
                bannerHeroImgMobile={bannerImgMobile}
                bannerImgAlt="Kristen, a real person with narcolepsy taking WAKIX, talking on her phone"
                heroNameImg={
                    <img
                        src={heroName}
                        alt="Kristen - Living with narcolepsy Taking WAKIX since 2020"
                    />
                }
            />

            <section className="inner-first icon-block">
                <div className="centered-content">
                    <div className="block-title">
                        <h1 className="font-40 asap">
                            The <span className="asap">WAKIX for You</span>{" "}
                            Support Program Is Here to Help
                        </h1>
                        <h2>
                            Personalized reimbursement and financial support
                        </h2>
                        <p>
                            Once your healthcare provider prescribes WAKIX, you
                            have the option to sign up for{" "}
                            <strong className="italic">WAKIX for You</strong>, a
                            program that provides individual reimbursement and
                            financial support based on your specific needs and
                            eligibility.
                        </p>
                    </div>
                </div>
            </section>

            <section className="icon-block">
                <div className="centered-content">
                    <Row>
                        <Col md={6}>
                            <div className="section-card__items">
                                <ul className="icon-list">
                                    <li>
                                        <div className="icon">
                                            <img
                                                src={icon1}
                                                alt="Prescription medicine"
                                            />
                                        </div>
                                        <div className="text">
                                            <p>
                                                Works with your insurance plan
                                                to determine coverage
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <img
                                                src={icon2}
                                                alt="Package shipment"
                                            />
                                        </div>
                                        <div className="text">
                                            <p>
                                                Ensures your prescription is
                                                sent to the{" "}
                                                <button
                                                    to="#"
                                                    className="tooltip"
                                                >
                                                    Specialty Pharmacy
                                                    <span className="tooltip-text">
                                                        WAKIX prescriptions are
                                                        filled through a
                                                        Specialty Pharmacy,
                                                        which may be different
                                                        than how you've received
                                                        other prescription
                                                        medications. A Specialty
                                                        Pharmacy is different
                                                        from a traditional
                                                        pharmacy, or retail
                                                        pharmacy. Specialty
                                                        Pharmacies are often
                                                        used for diseases or
                                                        disorders that have
                                                        smaller groups of people
                                                        and where additional
                                                        financial support might
                                                        be needed.
                                                    </span>
                                                </button>{" "}
                                                so they can arrange delivery
                                                directly to you
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="section-card__items">
                                <ul className="icon-list">
                                    <li>
                                        <div className="icon">
                                            <img
                                                src={icon3}
                                                alt="Financial support icon of a clipboard"
                                            />
                                        </div>
                                        <div className="text">
                                            <p>
                                                Offers financial support
                                                programs for eligible patients
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <img
                                                src={icon4}
                                                alt="Educational resources icon showing a brochure"
                                            />
                                        </div>
                                        <div className="text">
                                            <p>
                                                Provides educational resources
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="info-block bg-green">
                <div className="centered-content">
                    <div className="info-block__wrap">
                        <div className="info-block__media">
                            <img
                                src={copayIcon}
                                alt="$0 WAKIX copay program icon"
                                className="copay-img-icon"
                            />
                        </div>
                        <div className="info-block__title">
                            <p>
                                You may be eligible to pay as little as a $0
                                copay on your WAKIX prescription with the{" "}
                                <strong className="italic white">
                                    WAKIX for You
                                </strong>{" "}
                                Program*
                            </p>
                        </div>
                    </div>
                    <div className="info-block__content">
                        <p className="footnote font-14">
                            *This offer is valid only for patients who have
                            commercial (nongovernment-funded) insurance.
                            Additional terms and conditions apply.
                        </p>
                    </div>
                </div>
            </section>

            <section
                className="gray-bg download-overview"
                id="wakix-for-you-video"
            >
                <div className="centered-content">
                    <Row className="row align-items-center fit">
                        <Col lg={7}>
                            <CustomVideo
                                videoId="535699442"
                                videoIdParam="3234398592"
                                videoThumbnail={videoThumbnail}
                                altText="WAKIX for You patient support program video thumbnail"
                                customTagText="Patient Support Program Video"
                            />
                        </Col>
                        <Col lg={5}>
                            <div className="block-title">
                                <h2>
                                    Find more details about <br /> getting
                                    started
                                </h2>
                                <p>
                                    <strong className="italic blue">
                                        WAKIX&nbsp;for&nbsp;You
                                    </strong>{" "}
                                    is a patient support program that connects
                                    you to your own Patient Case Manager to help
                                    you get started and stay on track with your
                                    WAKIX treatment.{" "}
                                    <strong>
                                        Here's an overview of what you need to
                                        know.
                                    </strong>
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <div className="btn-wrap text-center">
                        <a
                            href="/pdf/WAKIX-for-you-patient-support-program.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary dwn-btn border-white dwn-btn"
                        >
                            Download overview
                            <svg
                                id="download_arrow"
                                data-name="download arrow"
                                xmlns="http://www.w3.org/2000/svg"
                                xlink="http://www.w3.org/1999/xlink"
                                width="21"
                                height="22"
                                viewBox="0 0 21 22"
                            >
                                {/* SVG path data */}
                            </svg>
                        </a>
                    </div>
                </div>
            </section>

            <section className="callout-section">
                <div className="centered-content">
                    <div className="block-title text-desktop">
                        <h2>Already been prescribed WAKIX?</h2>
                        <Link
                            to="#wakix-for-you-video"
                            className="btn btn-primary"
                        >
                            Watch Video
                        </Link>
                    </div>
                    <div className="callout-section__wrap">
                        <div className="callout-section__image">
                            <img
                                src={W4YThumbnail}
                                alt="WAKIX for You Patient Case Manager with headset"
                            />
                            <h2>Already been prescribed WAKIX?</h2>
                        </div>
                        <div className="callout-section__content">
                            <img src={W4YLogo} alt="WAKIX for You logo" />
                            <p className="text-primary asap asap-bold font-21 lh-24">
                                For questions or updates, contact{" "}
                                <i className="italic blue">
                                    WAKIX&nbsp;for&nbsp;You
                                </i>
                            </p>
                            <p>
                                <span className="asap asap-bold font-25">
                                    1-855-WAKIX4U
                                </span>{" "}
                                (1-855-925-4948)
                            </p>
                            <p className="acumen">
                                Monday–Friday, 8 am–8 pm ET
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="banner-block two-columns no-pb no-pt">
                <div className="col bg-img bg-img--alt">
                    <div className="inner bg-cover">
                        <img
                            src={expectationsImg}
                            className="banner-desktop"
                            alt="Chris discussing WAKIX and symptoms with his healthcare provider"
                        />
                        <img
                            src={expectationsMobileImg}
                            className="two-columns-mobile"
                            alt="Chris discussing WAKIX and symptoms with his healthcare provider"
                        />
                        <div className="content">
                            <h3 className="txt-shadow-white">
                                What should you <br />
                                expect with WAKIX?
                            </h3>
                        </div>
                        <div className="btn-wrap">
                            <Link
                                to="/starting-wakix/what-should-i-expect"
                                className="btn btn-primary btn-primary--md"
                            >
                                Find out
                                <TfiAngleRight />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col bg-img">
                    <div className="two-col__inner inner bg-cover">
                        {deviceSizes?.smUp ? (
                            <img
                                src={resourceImg}
                                className="two-col"
                                alt="Kristen, a real person living with narcolepsy, sitting with her laptop"
                            />
                        ) : (
                            <img
                                src={resourceMobileImg}
                                className="two-col"
                                alt="Kristen, a real person living with narcolepsy, sitting with her laptop"
                            />
                        )}
                        <div className="two-col__content content ml-76">
                            <div className="two-col__title text-white has-textShadow">
                                <h3>
                                    Access resources to start a conversation
                                    with your healthcare provider
                                </h3>
                            </div>
                            <div className="btn-wrap left-content">
                                <Link
                                    to="/resources-and-support/downloads"
                                    className="btn btn-primary btn-primary--md"
                                >
                                    Go now
                                    <TfiAngleRight />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SocialIconBlock />
        </HcpLayout>
    );
};

export default WakixForYouSupportProgram;

export const Head = () => (
    <Seo
        title="WAKIX for You Support Program | WAKIX® (pitolisant)"
        description="See available support through WAKIX for You. Eligible patients could pay as little as a $0 copay on their WAKIX prescription. Learn more."
        keywords="WAKIX for You, pitolisant, WAKIX support, WAKIX support phone, WAKIX for You phone, WAKIX support phone number, WAKIX for You phone number, WAKIX4U, WAKIX reimbursement, Pitolisant reimbursement, WAKIX financial support, Pitolisant financial support, WAKIX copay, WAKIX copay program, WAKIX patient support program"
    />
);
